// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";

function Branch({ homedata }) {
  const dispatch = useDispatch();
  //   const get_our_work_list = useSelector((state) =>
  //     state?.allapi?.get_our_work_list ? state?.allapi?.get_our_work_list : {}
  //   );

  const get_our_work_list = homedata;

  const ourWorklist = get_our_work_list?.ourbranch
    ? get_our_work_list?.ourbranch
    : [];

  //   useEffect(() => {
  //     dispatch(allapiAction.getourwork({ page: "home" }));
  //   }, []);

  return (
    <div className="container-fluid">
      <div className="text-center mt-5">
        <div className="ps-block__left mb-5">
          <h3>
            <b>OUR BRANCH</b>
          </h3>
        </div>
        <div className="row">
          {ourWorklist &&
            ourWorklist?.map((data) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-2"
                  key={data._id}
                >
                  <div className="our_work_card">
                    <a className="ps-block__overlay" href="#"></a>
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="box-shadow-new"
                    >
                      <img
                        src={URL?.API_BASE_URL + data?.feature_image}
                        alt={data?.title}
                        style={{
                          height: "auto",
                          width: "auto",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Branch;
